import React, { useRef } from "react";
import Overlay from "./Overlay";
import CloseIcon from "../assets/close.svg";
import styles from "./Modal.module.scss";
import useEscape from "../hooks/useEscape";

interface ModalProps {
  className: string;
  contentClass: string;
  title: string;
  onClose: () => void;
  children: any;
}

const Modal = ({
  className,
  contentClass,
  title,
  onClose,
  children,
}: ModalProps) => {
  useEscape(() => onClose());
  const modalRef = useRef(null);
  //useClickOutside does not work correctly for this component as it stands
  //if used here it will cause the modal to close when a customselect is clicked in the modal

  return (
    <>
      <Overlay />
      <div className={`${styles.container} ${className}`} ref={modalRef}>
        <div className={styles.header}>
          <span>{title}</span>
          <button onClick={onClose}>
            <img src={CloseIcon} alt="close" />
          </button>
        </div>
        <div className={contentClass ? contentClass : styles.content}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
