import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomSelect from "../../../common/CustomSelect";
import Modal from "../../../common/Modal";
import useUser from "../../../hooks/useUser";
import { removeFlash, setFlash } from "../../../state/flashSlice";
import { setUserSetting } from "../../../state/userSlice";
import styles from "./EditEmailFrequency.module.scss";

export default function EditEmailFrequency({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, settings, userStatus, settingsStatus } = useUser();
  const [hasUpdatedFrequency, setHasUpdatedFrequency] = useState(false);

  const [emailFrequency, setEmailFrequency] = useState(
    settings.providerEmailFrequency ?? ""
  );

  const frequencyOptions = [
    {
      value: "weekly",
      text: t("time.weekly", "Every week"),
    },
    {
      value: "bi-weekly",
      text: t("time.biWeekly", "Bi-weekly"),
    },
    {
      value: "monthly",
      text: t("time.monthly", "Monthly"),
    },
  ];

  useEffect(() => {
    if (hasUpdatedFrequency) {
      if (settingsStatus === "failed") {
        dispatch(
          setFlash({
            message: t("provider.emailFrequencyError"),
            type: "error",
          })
        );
      }
      if (settingsStatus === "success") {
        dispatch(
          setFlash({
            message: t("provider.emailFrequencySuccess"),
            type: "success",
          })
        );
        onClose();
      }
    }
  }, settingsStatus);

  const handleSave = async () => {
    dispatch(removeFlash());
    dispatch(
      setUserSetting({
        userId: user?.id,
        settingName: "providerEmailFrequency",
        setting: { value: emailFrequency },
      })
    );
    setHasUpdatedFrequency(true);
  };

  return (
    <Modal onClose={onClose} title={t("header.settings")}>
      <div className={styles.editProvider}>
        <div className={styles.selectWrapper}>
          <label className={"label label--block"}>
            {t("provider.emailFrequency")}
          </label>
          <CustomSelect
            onChange={(e) => setEmailFrequency(e.target.value)}
            options={frequencyOptions}
            value={emailFrequency}
            placeholder={"Frequency..."}
            renderOption={(option) => option.text}
          />
        </div>
        <button
          className={`btn-primary`}
          disabled={
            userStatus === "loading" ||
            settingsStatus === "loading" ||
            emailFrequency === ""
          }
          onClick={handleSave}
        >
          {t("general.save")}
        </button>
        <br></br>
      </div>
    </Modal>
  );
}

EditEmailFrequency.propTypes = {
  onClose: PropTypes.func,
};
