import config from "./config";
import { t } from "./i18n";
import { DateTime } from "luxon";

export default {
  login: async (email, password) => {
    let res, body;
    const stdError = t(
      "login.errors.generic",
      "Login failed due to system error, we suggest reloading the page and trying again."
    );

    const reqConfig = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "BasicCustom",
        "X-SK-PORTAL": `care-${config.platform}`,
      }),
      body: JSON.stringify({
        username: email,
        password: password,
        client_id: "sidekickhealth.com",
        grant_type: "session",
      }),
      credentials: "include",
    };

    try {
      res = await fetch(config.authBaseUrl + "/auth/session/login", reqConfig);
      body = await res.json();
    } catch (err) {
      console.error("Auth failed: fetch threw an error", {
        err: err,
      });
      throw new Error(stdError);
    }

    if (!body) {
      console.error("Auth failed: Empty body");
      throw new Error(stdError);
    }
    if (res.status === 400) {
      const error = body.error;
      if (error === "account_not_found") {
        console.info("Auth failed: Account not found", {
          email: email,
        });
        throw new Error(
          t("login.errors.userpass", "Wrong username or password.")
        );
      }
    }
    if (res.status === 401) {
      const error = body.error;
      if (error === "login_failed") {
        console.info("Auth failed: Incorrect credentials", {
          email: email,
        });
        throw new Error(
          t("login.errors.userpass", "Wrong username or password.")
        );
      }

      if (error === "account_blocked_until") {
        const time = DateTime.fromJSDate(
          new Date(body.error_description)
        ).toLocaleString(DateTime.TIME_SIMPLE);

        throw new Error(
          `${t(
            "login.errors.blocked",
            "Too many failed attempts. Account has been blocked until"
          )} ${time}`
        );
      }
    }

    if (!res.ok) {
      console.error(
        `Auth failed: Error from backend: ${res.status} ${res.statusText}`,
        { body: body }
      );

      if (body.error?.message) {
        // The regex below removes the error code at the front of the message,
        // "2|User not found" becomes "User not found"
        throw new Error(body.error.message.match(/^\d+\|(.*)/)[1]);
      }
      throw new Error(stdError);
    }

    return body;
  },

  logout: async () => {
    fetch(config.authBaseUrl + "/auth/session/logout", {
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-SK-PORTAL": `care-${config.platform}`,
      }),
      credentials: "include",
    });
  },
};
