import React, { useState } from "react";
import NeutralProfile from "../assets/neutral-profile.png";
import PropTypes from "prop-types";

import styles from "./Avatar.module.scss";

export default function Avatar({
  user,
  src,
  size,
  shape,
  shadow,
  transparent,
  children,
  className,
  onClick,
  showNameOnHover,
  title,
}) {
  const [showName, setShowName] = useState(false);

  const classes =
    styles.Avatar +
    ` ${className}` +
    ` ${styles[size]}` +
    ` ${styles[shape]}` +
    ` ${transparent ? styles.transparent : ""}` +
    ` ${shadow ? styles.shadow : ""}`;

  const onMouseEnter = () => {
    if (showNameOnHover && user?.fullName) {
      setShowName(true);
    }
  };

  const onMouseLeave = () => {
    setShowName(false);
  };

  const nameTooltip = () => (
    <div className={styles.tooltip}>{user.fullName}</div>
  );

  return (
    <div
      style={{
        backgroundImage: `url(${user?.imageHref || src || NeutralProfile})`,
      }}
      className={classes}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
    >
      {children}
      {showName && nameTooltip()}
    </div>
  );
}

Avatar.defaultProps = {
  size: "md",
  shape: "circle",
  className: "",
};

Avatar.propTypes = {
  user: PropTypes.shape({
    imageHref: PropTypes.string,
    fullName: PropTypes.string,
  }),
  src: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  shape: PropTypes.oneOf(["rounded", "circle", "square"]),
  shadow: PropTypes.bool,
  transparent: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  showNameOnHover: PropTypes.bool,
  title: PropTypes.string,
};
