import React, { Suspense, lazy } from "react";
import Helmet from "react-helmet";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { PrivateRoute, Logout } from "./auth";
import Header from "./nav-header/Header";
import config from "./config";
import LoadingSpinner from "./LoadingSpinner";

import "./App.scss";

const NotFound = lazy(() => import("./NotFound"));
const GoToUserPage = lazy(() => import("./go-to-user/GoToUserPage"));
const LoginPage = lazy(() => import("./login/LoginPage"));
const SignupPage = lazy(() => import("./signup/SignupPage"));
const ProgramsOverviewPage = lazy(() =>
  import("./programs/ProgramsOverviewPage")
);
const ProgramPage = lazy(() => import("./programs/ProgramPage"));
const ProviderPage = lazy(() => import("./programs/provider/ProviderPage"));
const ProfilePage = lazy(() => import("./profile/ProfilePage"));
const UserPage = lazy(() => import("./user/UserPage"));
const ResetPasswordPage = lazy(() =>
  import("./resetpassword/ResetPasswordPage")
);
const ImportAnthemUsersPage = lazy(() =>
  import("./import/ImportAnthemUsersPage")
);
const TeamPage = lazy(() => import("./team/TeamPage"));

function App() {
  const isUserInitialLoad = useSelector(
    (state) => state.user.userStatus === "initialLoad"
  );

  return (
    <Router>
      <div className="App">
        <Helmet titleTemplate="%s | Care Portal" defer={false} />

        <Header />

        {/* Avoid flash of login box and redirecting to index if user hasn't been loaded */}

        {!isUserInitialLoad && (
          <Suspense fallback={<LoadingSpinner />}>
            <Switch>
              <Route exact path="/login">
                <LoginPage />
              </Route>

              <Route exact path="/signup">
                <SignupPage />
              </Route>

              <Route exact path="/resetpassword">
                <ResetPasswordPage />
              </Route>

              <Route exact path="/logout">
                <Logout />
              </Route>

              <PrivateRoute exact path="/me">
                <ProfilePage />
              </PrivateRoute>

              <PrivateRoute path="/program/:program_id/locale/:locale/provider">
                <ProviderPage />
              </PrivateRoute>

              <PrivateRoute path="/program/:program_id/locale/:locale/users/:user_id">
                <UserPage />
              </PrivateRoute>

              <Route path={`/program/:program_id/locale/:locale/team`}>
                <TeamPage />
              </Route>

              <PrivateRoute path="/program/:program_id/locale/:locale">
                <ProgramPage />
              </PrivateRoute>

              <PrivateRoute exact path="/programs">
                <ProgramsOverviewPage />
              </PrivateRoute>

              <PrivateRoute exact path="/importing">
                <ImportAnthemUsersPage />
              </PrivateRoute>

              <PrivateRoute exact path="/go-to-user">
                <GoToUserPage />
              </PrivateRoute>

              <PrivateRoute exact path="/">
                <Redirect to="/programs" />
              </PrivateRoute>

              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        )}

        {config.enableZendesk && (
          <Zendesk
            defer
            zendeskKey={config.zendeskKey}
            {...config.zendeskSettings}
            onLoaded={() => {
              if (config.isAnthem) {
                ZendeskAPI("webWidget", "setLocale", "en");
              }
            }}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
