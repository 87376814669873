import { useEffect } from "react";

export default function useOnDocClick(handler) {
  useEffect(() => {
    const listener = (event) => {
      handler(event);
    };

    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  }, [handler]);
}
