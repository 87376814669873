const COMPLIANCE_ADMIN_PRIVILEGES = 8;
const PROVIDER_PRIVILEGES = 9;
const LEAD_COACH_PRIVILEGES = 15;
const ADMIN_PRIVILEGES = 20;

export {
  LEAD_COACH_PRIVILEGES,
  ADMIN_PRIVILEGES,
  COMPLIANCE_ADMIN_PRIVILEGES,
  PROVIDER_PRIVILEGES,
};
