import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import store from "./state/store";
import { fetchUser } from "./state/userSlice";
import AppErrorBoundary from "./AppErrorBoundary";
import App from "./App";
import "./i18n";

import "./index.scss";

if (config.sentry.enable) {
  Sentry.init({
    dsn: config.sentry.dsn,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    environment: config.platform,

    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0,
  });
}

store.dispatch(fetchUser({}));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppErrorBoundary>
        <App />
      </AppErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
