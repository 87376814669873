import { isLocalhost } from "./helpers/isLocalHost";

const config = {
  platform: process.env.REACT_APP_SK_PLATFORM,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  authBaseUrl: process.env.REACT_APP_AUTH_API_BASE_URL,
  legalBaseUrl: process.env.REACT_APP_LEGAL_BASE_URL,
  privacyVersion: Number(process.env.REACT_APP_PRIVACY_POLICY_VERSION),
  termsVersion: Number(process.env.REACT_APP_TERMS_AND_CONDITIONS_VERSION),
  appId: Number(process.env.REACT_APP_SK_APP_ID),
  allowInviteProviders: process.env.REACT_APP_ALLOW_INVITE_PROVIDERS === "true",

  isAnthem: process.env.IS_ANTHEM === "true",
  useSms: process.env.REACT_APP_USE_SMS === "true" || isLocalhost,

  showProviderSettings: process.env.REACT_APP_SHOW_PROVIDER_SETTINGS === "true",
  allowUserConfigurableUnits:
    process.env.REACT_APP_ALLOW_USER_CONFIGURABLE_UNITS === "true",
  allowLanguageSelection:
    process.env.REACT_APP_ALLOW_LANGUAGE_SELECTION === "true",

  enableZendesk: process.env.REACT_APP_ENABLE_ZENDESK === "true",
  zendeskKey: process.env.REACT_APP_ZENDESK_KEY,
  // https://developer.zendesk.com/embeddables/docs/widget/settings
  zendeskSettings: {
    color: {
      theme: "#0050ff",
    },
    position: {
      horizontal: "left",
    },
  },

  sentry: {
    enable: !isLocalhost,
    dsn:
      "https://d394547dcaf24e1f966d59cb48eee8b7@o491702.ingest.sentry.io/5557684",
  },

  anthemHRSDeepLinkBaseUrl: process.env.REACT_APP_ANTHEM_HRS_DEEP_LINK_BASE_URL,
  anthemNurselineDeepLinkBaseUrl:
    process.env.REACT_APP_ANTHEM_NURSELINE_DEEP_LINK_BASE_URL,
  anthemCareCompassDeepLinkBaseUrl:
    process.env.REACT_APP_ANTHEM_CARE_COMPASS_DEEP_LINK_BASE_URL,
  anthemHipDeepLinkBaseUrl: process.env.REACT_APP_ANTHEM_HIP_DEEP_LINK_BASE_URL,
};

if (config.platform === "sidekick-local") {
  console.log("Establishing connection with local API");
  config.authBaseUrl = "http://localhost:8083/v1";
  config.apiBaseUrl = "http://localhost:8081/_ah/api/sidekickendpoint/v8";
}

export default config;
