import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { authReducer } from "../auth";
import { userReducer } from "./userSlice";
import { flashReducer } from "./flashSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    flash: flashReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
