import config from "../config";
import { createBrowserHistory } from "history";
import store from "../state/store";
import { setFlash } from "../state/flashSlice";
import { t } from "../i18n";
import AccessDeniedIcon from "../assets/key-door.svg";

async function apiRequest(path, method, payload, returnError = false) {
  const reqConfig = {
    method: method,
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-SK-PORTAL": `care-${config.platform}`,
      Pragma: "no-cache",
      "Cache-Control": "stale-while-revalidate=86400",
    }),
    credentials: "include",
  };

  if (payload) {
    reqConfig.body = JSON.stringify(payload);
  }

  try {
    const res = await fetch(config.apiBaseUrl + path, reqConfig);

    // body sometimes empty on DELETE responses
    let body;
    try {
      body = await res.json();
    } catch {
      body = {};
    }

    if (!res.ok) {
      if (res.status === 401) {
        const history = createBrowserHistory();
        const routesToIgnore = ["/", "/login", "/resetpassword", "/signup"];

        if (!routesToIgnore.includes(history.location.pathname)) {
          history.push({
            pathname: "/login?sessionExpired=true",
            state: {
              from: history.location,
            },
          });
          history.go();
        }
      } else if (res.status === 403) {
        if (!store.getState().flash.flashMessage) {
          const history = createBrowserHistory();
          if (history.location.pathname !== "/login") {
            const flash = {
              message: t("login.errors.missingPrivileges"),
              type: "error",
              icon: AccessDeniedIcon,
            };
            store.dispatch(setFlash(flash));
          }
        }
        console.error(
          `Missing privileges ${res.status}: ${body.error?.message}`,
          { body: body }
        );
        return { res, body };
      } else {
        console.error(
          `Request to backend returned an error ${res.status}: ${body.error?.message}`,
          { body: body }
        );
        return { res, body };
      }

      if (returnError) {
        return { res: res, body: body };
      } else {
        return null;
      }
    }

    return { res, data: body };
  } catch (err) {
    console.error("Request to backend failed", {
      err: err,
    });

    return null;
  }
}

export default {
  get: async (path) => {
    return apiRequest(path, "GET");
  },

  post: async (path, payload, returnError) => {
    return apiRequest(path, "POST", payload, returnError);
  },

  put: async (path, payload) => {
    return apiRequest(path, "PUT", payload);
  },

  delete: async (path, returnError) => {
    return apiRequest(path, "DELETE", null, returnError);
  },
};
