import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useOnClickOutside from "../hooks/useOnClickOutside";
import styles from "./CustomSelect.module.scss";
import { t } from "../i18n";
import getStringLengthInPixels from "../helpers/string/getStringLengthInPixels";

const OPTION_TEXT_PIXELS_LIMIT = 173;

export default function CustomSelect({
  value,
  valueKey,
  onChange,
  className,
  disabled,
  renderOption,
  renderSelectedOption,
  options,
  placeholder,
  noOptions,
}) {
  const classes = `${styles.CustomSelect} select input ${className} ${
    disabled ? styles.disabled : ""
  }`;
  const [showOptions, setShowOptions] = useState(false);

  const getSelectedOption = () => {
    return options.find((option) => option[valueKey] === value);
  };

  const [selectedOption, setSelectedOption] = useState(getSelectedOption());

  const listRef = useRef();
  useOnClickOutside(listRef, () => {
    if (showOptions) {
      setShowOptions(false);
    }
  });

  useEffect(() => {
    setSelectedOption(getSelectedOption());
  }, [value]);

  if (renderOption === undefined) {
    renderOption = (option) => {
      if (option === undefined) {
        return null;
      }

      return option[valueKey];
    };
  }

  if (renderSelectedOption === undefined) {
    renderSelectedOption = renderOption;
  }

  const handleOnClickOption = (e, callback) => {
    const event = {
      ...e,
      target: {
        value: e.currentTarget.dataset.value,
      },
    };
    if (callback) {
      callback(e);
    }
    return onChange(event);
  };

  const handleOnClickSelect = () => {
    if (!disabled) {
      setShowOptions(!showOptions);
    }
  };

  return (
    <div
      className={classes}
      onClick={handleOnClickSelect}
      tabIndex={disabled ? null : "0"}
      disabled={disabled}
    >
      <span>
        {selectedOption ? renderSelectedOption(selectedOption) : placeholder}
      </span>
      {showOptions && (
        <ul className={styles.options} ref={listRef}>
          {options.map((option, index) => {
            return (
              <li
                key={option[valueKey]}
                onClick={(e) => handleOnClickOption(e, option.onClick)}
                className={`${
                  option[valueKey] === value ? styles.selected : ""
                } ${
                  getStringLengthInPixels(option?.text) >
                  OPTION_TEXT_PIXELS_LIMIT
                    ? styles.carousel
                    : ""
                }`}
                data-value={option[valueKey]}
              >
                {index !== 0 && <div className={styles.separator}></div>}
                {renderOption(option)}
              </li>
            );
          })}
          {options.length === 0 && <li>{noOptions}</li>}
        </ul>
      )}
      <select value={value} readOnly>
        {options.map((option) => {
          return (
            <option value={option[valueKey]} key={option[valueKey]}></option>
          );
        })}
      </select>
    </div>
  );
}

CustomSelect.defaultProps = {
  className: "",
  valueKey: "value",
  disabled: false,
  placeholder: t("select.placeholder"),
  noOptions: t("select.noOptions"),
  options: [],
  onChange: () => {},
};

CustomSelect.propTypes = {
  value: PropTypes.any,
  valueKey: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  renderOption: PropTypes.func,
  renderSelectedOption: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.node,
  noOptions: PropTypes.node,
};
