import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useLoggedIn from "./hooks/useLoggedIn";
import { fetchUser } from "./state/userSlice";
import authApi from "./authApi";

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useLoggedIn();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

// routable logout component
export function Logout() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout(() => history.replace("/login")));
  });

  return null;
}

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password, cb }, { dispatch }) => {
    const res = await dispatch(loginRequest({ email, password, cb }));
    if (res?.payload?.auth_account_id) {
      const userRes = await dispatch(fetchUser({}));
      if (userRes.error) {
        throw new Error(userRes.error.message);
      }
    }
  }
);

const loginRequest = createAsyncThunk(
  "auth/loginRequest",
  async ({ email, password, cb }) => {
    const response = await authApi.login(email, password);

    if (cb) cb();

    return response;
  }
);

export const logout = createAsyncThunk("auth/logout", async (cb) => {
  authApi.logout();

  if (cb) cb();

  return true;
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginstatus: "",
    loginerror: null,
    logoutstatus: "",
    logouterror: null,
  },
  reducers: {},
  extraReducers: {
    [loginRequest.pending]: (state) => {
      state.loginstatus = "loading";
      state.loginerror = null;
    },
    [loginRequest.fulfilled]: (state) => {
      state.loginstatus = "success";
      state.loginerror = null;
    },
    [loginRequest.rejected]: (state, action) => {
      state.loginstatus = "failed";
      state.loginerror = action.error.message;
    },
    [login.rejected]: (state, action) => {
      state.loginstatus = "failed";
      state.loginerror = action.error.message;
    },
    [logout.pending]: (state) => {
      state.logoutstatus = "loading";
      state.logouterror = null;
    },
    [logout.fulfilled]: (state) => {
      state.logoutstatus = "success";
      state.logouterror = null;
    },
    [logout.rejected]: (state, action) => {
      state.logoutstatus = "failed";
      state.logouterror = action.error.message;
    },
  },
});

const authReducer = authSlice.reducer;

export { authReducer };
