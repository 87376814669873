import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import styles from "./NavProfile.module.scss";

import ProfileIcon from "../assets/user.svg";
import SettingsIcon from "../assets/cog.svg";
// import TeamIcon from "../assets/team.svg";
import LogoutIcon from "../assets/logout.svg";
import Avatar from "../common/Avatar";
import config from "../config";
import {
  COMPLIANCE_ADMIN_PRIVILEGES,
  PROVIDER_PRIVILEGES,
} from "../constants/privileges";
import useOnDocClick from "../hooks/onDocClick";
import EditEmailFrequency from "../user/information/modals/EditEmailFrequency";

export default function NavProfile({ user }) {
  const { t } = useTranslation();

  const [providerSettingsModalOpen, setProviderSettingsModalOpen] = useState(
    false
  );
  const [showDropdown, setShowDropdown] = useState(false);

  useOnDocClick(() =>
    showDropdown && !providerSettingsModalOpen ? setShowDropdown(false) : null
  );

  const getDropDownItems = () => {
    switch (parseInt(user?.privileges)) {
      case COMPLIANCE_ADMIN_PRIVILEGES:
        return [
          {
            link: "/me",
            text: t("header.myPage", "My page"),
            icon: ProfileIcon,
          },
          // {
          //   link: "/team",
          //   text: t("team.team", "Team"),
          //   icon: TeamIcon,
          // },
        ];
      case PROVIDER_PRIVILEGES:
        return config.showProviderSettings
          ? [
              {
                onClick: () => setProviderSettingsModalOpen(true),
                text: t("header.settings", "Settings"),
                icon: SettingsIcon,
              },
            ]
          : [];
      default:
        return [
          {
            link: "/me",
            text: t("header.myPage", "My page"),
            icon: ProfileIcon,
          },
        ];
    }
  };

  const navItems = getDropDownItems()
    .concat([
      {
        link: "/logout",
        text: t("header.logout", "Log out"),
        icon: LogoutIcon,
      },
    ])
    .map(({ link, text, icon, onClick }) => {
      return (
        <li key={text} className={styles.NavProfile__NavItem}>
          {link ? (
            <Link to={link}>
              <img src={icon} alt={text} className={styles.NavProfile__Icon} />
              <span>{text}</span>
            </Link>
          ) : onClick ? (
            <button onClick={onClick}>
              <img src={icon} alt={text} className={styles.NavProfile__Icon} />
              <span>{text}</span>
            </button>
          ) : null}
        </li>
      );
    });

  return (
    <>
      <button
        className={styles.NavProfile__User}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <Avatar
          user={user}
          size="sm"
          className={styles.NavProfile__User__Avatar}
        />
        {user.displayName}
      </button>
      {showDropdown && (
        <div className={styles.NavProfile__ProfileDropdown}>
          <ul className={styles.NavProfile}>
            <div className={styles.NavProfile__Notch} />
            {navItems}
          </ul>
        </div>
      )}
      {providerSettingsModalOpen && (
        <EditEmailFrequency
          onClose={() => setProviderSettingsModalOpen(false)}
          externalUser={user}
        />
      )}
    </>
  );
}

NavProfile.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    imageHref: PropTypes.string,
    privileges: PropTypes.string,
  }),
};
