import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SidekickLogo from "../assets/sidekick-logo.svg";
import AnthemLogo from "../assets/concierge-care-logo.png";
//import NotificationIcon from "../assets/notifications.svg";
//import MessagesIcon from "../assets/messages.svg";
//import Notification from "../common/Notification";
import NavProfile from "./NavProfile";
import config from "../config";
import styles from "./NavHeader.module.scss";

export default function NavHeader({
  /*notificationCount, messageCount,*/ user,
}) {
  return (
    <div className={styles.NavHeader}>
      <div className={styles.HeaderContent}>
        <Link to="/">
          <img
            src={config.isAnthem ? AnthemLogo : SidekickLogo}
            alt={`${config.isAnthem ? "Anthem" : "Sidekick"} Logo`}
            className={styles.HeaderContent__Logo}
          />
        </Link>

        {user && (
          <div className={styles.HeaderContent__Right}>
            {/*
          <div className={styles.HeaderContent__Notifications}>
            <img
              src={NotificationIcon}
              alt="notifications"
              className={styles.HeaderContent__Icon}
            />
            <Notification
              count={notificationCount}
              size="sm"
              className={styles.HeaderContent__Notifications__Notification}
            />
          </div>
          <div className={styles.HeaderContent__Messages}>
            <img
              src={MessagesIcon}
              alt="notifications"
              className={styles.HeaderContent__Icon}
            />
            <Notification
              count={messageCount}
              size="sm"
              className={styles.HeaderContent__Messages__Notification}
            />
          </div>
          */}
            <NavProfile user={user} />
          </div>
        )}
      </div>
    </div>
  );
}

NavHeader.propTypes = {
  notificationCount: PropTypes.number.isRequired,
  messageCount: PropTypes.number.isRequired,
  user: PropTypes.shape({
    displayName: PropTypes.string,
    imageHref: PropTypes.string,
  }),
};
