import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

export default class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (error.name === "ChunkLoadError") {
      window.location.reload();
    } else {
      console.error("AppErrorBoundary: ", error);
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="unplugged-error">
          <div>
            <h1>Oops!</h1>
            <p>
              Something went wrong. Please try reloading the page. If the
              problem persists please contact an admin.
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node,
};
