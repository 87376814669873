import React from "react";
import PropTypes from "prop-types";
import styles from "./Overlay.module.scss";

export default function Overlay({ className }) {
  return <div className={`${styles.Overlay} ${className}`} />;
}

Overlay.defaultProps = {
  className: "",
};

Overlay.propTypes = {
  className: PropTypes.string,
};
