import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import config from "./config";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require("./translations/en.json"),
      },
      is: {
        translation: require("./translations/is.json"),
      },
    },
    fallbackLng: { default: ["en"] },

    interpolation: {
      escapeValue: false, // react already saves from xss
    },
  });

if (config.isAnthem) {
  i18n.changeLanguage("en");
}

export default i18n;

const t = i18n.t.bind(i18n);
export { t };
